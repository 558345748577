import React, { useEffect, useState } from "react";

import "./couple.css";
import { useSelector } from "react-redux";

const Couple = () => {
    const template = useSelector((state) => state.template);

    return (
        <div
            className="couple"
            style={{
                background: template?.data.primaryColor,
                color: template?.data.colorOnPrimary,
            }}
        >
            <span className="couple-bride-groom-label league-spartan-regular">
                Groom
            </span>
            <span className="couple-bride-groom-and league-spartan-regular">
                and
            </span>
            <br />
            <span className="couple-bride-groom-label league-spartan-regular">
                Bride
            </span>

            <div className="couple-wrapper">
                <div className="couple-column text-right">
                    <div className="couple-name league-spartan-medium">
                        {template?.data.groomName}
                    </div>
                    <div className="couple-parent kite-one-regular">
                        Proudly Son of
                        <br />
                        <div style={{ marginTop: 12 }}>
                            {template?.data.groomFatherName}
                            <br />&<br />
                            {template?.data.groomMotherName}
                        </div>
                    </div>
                </div>
                <div className="couple-column">
                    <img
                        className="couple-img-profile"
                        src={template?.data.groomImg}
                        alt=""
                    />
                </div>
            </div>

            <br />
            <div className="couple-wrapper">
                <div className="couple-column">
                    <img
                        className="couple-img-profile"
                        src={template?.data.brideImg}
                        alt=""
                    />
                </div>
                <div className="couple-column">
                    <div className="couple-name league-spartan-medium">
                        {template?.data.brideName}
                    </div>
                    <div className="couple-parent kite-one-regular">
                        Lovely Daughter of
                        <br />
                        <div style={{ marginTop: 12 }}>
                            {template?.data.brideFatherName}
                            <br />&<br />
                            {template?.data.brideMotherName}
                        </div>
                    </div>
                </div>
            </div>

            <div className="couple-words">
                <div className="couple-words-title league-spartan-bold text-center">
                    ,,
                </div>
                <div className="couple-words-content kite-one-regular text-center">
                    {template?.data.wordsOnCouplePage}
                </div>
            </div>
        </div>
    );
};

export default Couple;
