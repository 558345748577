import { configureStore } from "@reduxjs/toolkit";
import templateSlice from "./reduxslice/templateSlice";
import invitationSlice from "./reduxslice/invitationSlice";

export default configureStore({
    reducer: {
        template: templateSlice,
        invitation: invitationSlice,
    },
});
