import React, { Component, useState } from "react";

import "./gallery.css";
import { useSelector } from "react-redux";

function Gallery() {
    const template = useSelector((state) => state.template);

    return (
        <div
            className="gallery-container"
            style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("${template?.data.galleryImages[0]}")`,
            }}
        >
            <div style={{ display: "flex" }}>
                {template?.data.galleryImages.map((imgURL, i) => {
                    return (
                        <div key={i}>
                            <img className="gallery-item" src={imgURL} alt="" />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Gallery;
