import logo from "./logo.svg";
import "./App.css";
import Cover from "./sections/cover/Cover";
import Countdown from "./sections/countdown/Countdown";
import Couple from "./sections/couple/Couple";
import { useEffect, useState } from "react";
import Agenda from "./sections/agenda/Agenda";
import RSVP from "./sections/rsvp/RSVP";
import Wishes from "./sections/wishes/Wishes";
import client from "./repository/client";
import { useSelector, useDispatch } from "react-redux";
import { setInvitation } from "./reduxslice/invitationSlice";
import { setTemplate } from "./reduxslice/templateSlice";
import Gift from "./sections/gift/Gift";
import Footer from "./sections/footer/Footer";
import Gallery from "./sections/gallery/Gallery";
import { ToastContainer } from "react-toastify";

function App() {
    const invitation = useSelector((state) => state.invitation);
    const template = useSelector((state) => state.template);
    const [hasInvitation, setHasInvitation] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        // for handle refresh page if position not in cover page
        setTimeout(() => window.scrollTo(0, 0), 200);
        getInvitation();
    }, []);

    const getInvitation = async () => {
        try {
            // get inv query params
            const invitationSerial = new URLSearchParams(
                window.location.search
            ).get("inv");

            const templateSerial = new URLSearchParams(
                window.location.search
            ).get("t");

            if (!invitationSerial && !templateSerial) {
                setHasInvitation(false);
                return;
            }

            let weddingTemplateSerial = templateSerial;
            if (invitationSerial) {
                const invitationResponse = await client.getInvitation(
                    invitationSerial
                );
                const invitation = invitationResponse.data.data;
                weddingTemplateSerial = invitation.weddingTemplateSerial;
                dispatch(setInvitation(invitation));
            } else {
                dispatch(setInvitation({ onlyTemplate: true }));
            }

            getTemplate(weddingTemplateSerial);
        } catch (err) {
            console.log(err);
        }
    };
    const getTemplate = async (weddingTemplateSerial) => {
        try {
            const templateResponse = await client.getTemplate(
                weddingTemplateSerial
            );
            const template = templateResponse.data.data;
            dispatch(setTemplate(template));
            setTimeout(() => window.scrollTo(0, 0), 200);
            document.title = `The Wedding of ${template.data.groomShortName} and ${template.data.brideShortName} | Sora.com`;
        } catch (err) {
            console.log(err);
        }
    };

    const sectionsMap = {
        countdown: <Countdown key={"countdown"} />,
        couple: <Couple key={"couple"} />,
        agenda: <Agenda key={"agenda"} />,
        rsvp: <RSVP key={"rsvp"} />,
        wishes: <Wishes key={"wishes"} />,
        gift: <Gift key={"gift"} />,
        gallery: <Gallery key={"gallery"} />,
    };

    if (!hasInvitation) {
        return (
            <div
                className="App"
                style={{ background: template?.data.backgroundColor }}
            >
                <div className="loading">
                    Oops, sepertinya anda tidak memiliki undangan.
                </div>
            </div>
        );
    }

    return invitation ? (
        <div
            className="App"
            style={{ background: template?.data.backgroundColor }}
        >
            <Cover />
            {template?.data.sections.map((section) => {
                if (invitation.onlyTemplate && section == "rsvp") {
                    return <></>;
                }
                return sectionsMap[section];
            })}
            <Footer />
            <ToastContainer
                style={{
                    padding: "24px",
                }}
            />
        </div>
    ) : (
        <div
            className="App"
            style={{ background: template?.data.backgroundColor }}
        >
            <div className="loading">
                <div
                    className="loader"
                    style={{
                        "--_g": `no-repeat radial-gradient(circle closest-side, #000 90%, #0000)`,
                    }}
                ></div>
            </div>
        </div>
    );
}

export default App;
