import React, { useState } from "react";

import "./rsvp.css";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import client from "../../repository/client";
function RSVP() {
    const template = useSelector((state) => state.template);
    const invitation = useSelector((state) => state.invitation);

    const [attendance, setAttendance] = useState(null);
    const [isSendAttendance, setSendAttendance] = useState(false);
    const [hideSubmitBtn, setHideSubmitButton] = useState(false);

    const confirmAttendance = async () => {
        if (isSendAttendance) return;
        if (!attendance) {
            toast("Confirm your attendance!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
                closeButton: false,
                bodyClassName: "league-spartan-medium",
                style: {
                    backgroundColor: template?.data.primaryColor,
                    color: template?.data.colorOnPrimary,
                },
            });
            return;
        }

        setSendAttendance(true);
        try {
            const result = await client.setAttendanceConfirmarion(
                invitation.serial,
                +attendance
            );
            if (result.status == 200) {
                setHideSubmitButton(true);
            }
        } catch (err) {
            console.log(err);
            toast("Failed confirm your attendance !", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
                closeButton: false,
                bodyClassName: "league-spartan-medium",
                style: {
                    backgroundColor: template?.data.primaryColor,
                    color: template?.data.colorOnPrimary,
                },
            });
        } finally {
            setSendAttendance(false);
        }
    };

    return (
        <div
            className="rsvp-container"
            style={{ color: template?.data.primaryColor }}
        >
            <div className="league-spartan-regular couple-bride-groom-label">
                RSVP
            </div>
            <div className="rsvp-form">
                <div className="input-box-rsvp">
                    <div className="league-spartan-medium">Name</div>
                    <input
                        type="text"
                        disabled
                        defaultValue={invitation.name}
                    />
                </div>
                <div className="input-box-rsvp">
                    <div className="league-spartan-medium">Contact</div>
                    <input
                        type="text"
                        disabled
                        defaultValue={invitation.identifier}
                    />
                </div>
                <div className="input-box-rsvp">
                    <div className="league-spartan-medium">Attendance</div>
                    <select
                        name=""
                        id=""
                        disabled={invitation.attendanceStatus != null}
                        onChange={(e) => {
                            const attendance = e.target.value;
                            if (isNaN(attendance)) {
                                setAttendance(null);
                                return;
                            }
                            setAttendance(attendance);
                        }}
                        value={invitation.attendanceStatus}
                    >
                        <option>Set attendance</option>
                        <option value="1">Attend</option>
                        <option value="0">Not Attend</option>
                        <option value="2">Not Sure</option>
                    </select>
                </div>
                {invitation.attendanceStatus == null && !hideSubmitBtn ? (
                    <div className="input-box-rsvp">
                        <input
                            type="submit"
                            className="btn league-spartan-medium btn-primary"
                            onClick={() => {
                                confirmAttendance();
                            }}
                            value={"Send"}
                            style={{
                                backgroundColor: template?.data.primaryColor,
                                color: template?.data.colorOnPrimary,
                            }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default RSVP;
