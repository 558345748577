import React, { Component } from "react";

import "./agenda.css";
import { useSelector } from "react-redux";

function Agenda() {
    const template = useSelector((state) => state.template);
    return (
        <div className="agenda" style={{ color: template?.data.primaryColor }}>
            <div className="">
                <img
                    className="img-top-agenda"
                    src={template?.data.backgroundImageOnEventPage}
                    alt=""
                />
            </div>

            <div className="agenda-container">
                <div className=" text-right">
                    <span className="couple-bride-groom-label league-spartan-regular">
                        When
                    </span>
                    <span className="couple-bride-groom-and league-spartan-regular">
                        and
                    </span>
                    <br />
                    <span className="couple-bride-groom-label league-spartan-regular">
                        Where
                    </span>
                </div>

                <div className="agenda-content text-center">
                    <img
                        className="agenda-ornament"
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/ornament-countdown.png"
                        alt=""
                    ></img>

                    <div className="league-spartan-bold agenda-label-title">
                        Event Details
                    </div>

                    {template?.data.eventDetails.map((evt, i) => {
                        return (
                            <div
                                key={i}
                                dangerouslySetInnerHTML={{ __html: evt }}
                            />
                        );
                    })}

                    <button
                        className="btn league-spartan-medium btn-primary"
                        onClick={() => {
                            window.open(template?.data.locationURL, "_blank");
                        }}
                        style={{
                            backgroundColor: template?.data.primaryColor,
                            color: template?.data.colorOnPrimary,
                        }}
                    >
                        Google Map
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Agenda;
