import React, { useEffect, useRef, useState } from "react";

import "./wishes.css";
import { useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import client from "../../repository/client";

function Wishes() {
    const template = useSelector((state) => state.template);
    const invitation = useSelector((state) => state.invitation);

    const [message, setMessage] = useState("");
    const [isSendMessage, setIsSendMessage] = useState(false);
    const [wishes, setWishes] = useState([]);

    const makeAWish = async () => {
        if (isSendMessage) return;
        if (message == "") {
            toast("Write you message!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
                closeButton: false,
                bodyClassName: "league-spartan-medium",
                style: {
                    backgroundColor: template?.data.primaryColor,
                    color: template?.data.colorOnPrimary,
                },
            });
            return;
        }

        setIsSendMessage(true);
        const invitationSerial = invitation.serial;
        try {
            const result = await client.makeAWish(invitationSerial, message);
            setMessage("");

            let oldWishes = wishes;
            oldWishes.unshift({
                message: message,
                name: invitation.name,
                timestamp: new Date().toISOString(),
                attendanceStatus: invitation.attendanceStatus,
            });

            const element = document.getElementById("wish-list");
            element?.scrollIntoView({
                behavior: "smooth",
            });
        } catch (err) {
            console.log(err);
            toast("Failed to send your wish!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
                closeButton: false,
                bodyClassName: "league-spartan-medium",
                style: {
                    backgroundColor: template?.data.primaryColor,
                    color: template?.data.colorOnPrimary,
                },
            });
        } finally {
            setIsSendMessage(false);
        }
    };

    const getWishesByInvitationSerial = async () => {
        try {
            const result = await client.getWishesByInvitationSerial(
                invitation.serial || template.weddingSerial
            );
            if (result.status == 200) {
                setWishes(result.data.data.wishes || []);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getWishesByInvitationSerial();
    }, []);

    return (
        <div className="wishes" style={{ color: template?.data.primaryColor }}>
            {!invitation.onlyTemplate && (
                <div>
                    <div className="wishes-title league-spartan-medium couple-bride-groom-label text-right">
                        Your
                        <br />
                        Wishes
                    </div>
                    <div className="wishes-content">
                        <div className="rsvp-form wishes-form">
                            <img
                                src={template?.data.backgroundImageOnWishesPage}
                                alt=""
                                style={{
                                    width: "100%",
                                    position: "absolute",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                            <div className="wishes-form-overlay"></div>
                            <div className="input-box-rsvp">
                                <div className="league-spartan-medium">
                                    Name
                                </div>
                                <input
                                    type="text"
                                    defaultValue={invitation?.name}
                                    contentEditable={false}
                                />
                            </div>
                            <div className="input-box-rsvp">
                                <div className="league-spartan-medium">
                                    Wishes
                                </div>
                                <textarea
                                    type="text"
                                    rows={5}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                    value={message}
                                />
                            </div>
                            <div className="input-box-rsvp">
                                <input
                                    type="submit"
                                    className="btn league-spartan-medium btn-primary"
                                    onClick={() => {
                                        makeAWish();
                                    }}
                                    value={"Send"}
                                    style={{
                                        backgroundColor:
                                            template?.data.primaryColor,
                                        color: template?.data.colorOnPrimary,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {wishes.length > 0 && (
                <div
                    id="wish-list"
                    className="wishes-message-container scroll"
                    style={{ background: template?.data.primaryColor }}
                >
                    <div
                        className="league-spartan-medium wishes-message-container-title"
                        style={{ color: template?.data.colorOnPrimary }}
                    >
                        What they said about us:
                    </div>
                    <div className="">
                        {wishes.map((wish, i) => {
                            return (
                                <div
                                    key={i}
                                    className="wishes-message-item"
                                    style={{
                                        background:
                                            template?.data.colorOnPrimary,
                                        color: template?.data.primaryColor,
                                    }}
                                >
                                    <div className="league-spartan-medium wishes-message-item-name">
                                        {wish.name}
                                    </div>
                                    <div className="kite-one-regular wishes-message-item-message">
                                        "{wish.message}"
                                    </div>
                                    {!template?.data.isHideWishesTime && (
                                        <div className="league-spartan-regular wishes-message-item-timestamp">
                                            {formatDateWIB(
                                                new Date(wish.timestamp)
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

function formatDateWIB(date) {
    const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "Asia/Jakarta",
    };

    const formattedDate = new Intl.DateTimeFormat("id-ID", options).format(
        date
    );
    return `${formattedDate.replace(" pukul", ",")} WIB`;
}

export default Wishes;
