import axios from "axios";

const instance = axios.create({
    baseURL: "https://api.sorainvitation.com",
});

export default {
    getInvitation: async (serial) => {
        return await instance({
            method: "GET",
            url: "/invitation/" + serial,
            headers: {
                "content-type": "application/json", // override instance defaults
            },
        });
    },
    getTemplate: async (templateSerial) => {
        return await instance({
            method: "GET",
            url: "/wedding-template/" + templateSerial,
            headers: {
                "content-type": "application/json", // override instance defaults
            },
        });
    },
    setAttendanceConfirmarion: async (invitationSerial, attendanceStatus) => {
        return await instance({
            method: "PUT",
            url: "/invitation/attendance-confirmation",
            headers: {
                "content-type": "application/json", // override instance defaults
            },
            data: {
                invitationSerial,
                attendanceStatus,
            },
        });
    },
    makeAWish: async (invitationSerial, message) => {
        return await instance({
            method: "POST",
            url: "/wish",
            headers: {
                "content-type": "application/json", // override instance defaults
            },
            data: {
                invitationSerial,
                message,
            },
        });
    },
    getWishesByInvitationSerial: async (invitationSerial) => {
        return await instance({
            method: "GET",
            url: "/wish/" + invitationSerial,
            headers: {
                "content-type": "application/json", // override instance defaults
            },
        });
    },
};
