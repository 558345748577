import React, { useRef, useState } from "react";
import "./cover.css";
import { useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import QRCode from "react-qr-code";

const Cover = () => {
    const template = useSelector((state) => state.template);
    const invitation = useSelector((state) => state.invitation);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(null);

    const [open, setOpen] = useState(false);

    const [hideCover, setHideCover] = useState(false);

    return (
        <div
            className="cover"
            style={{
                background: `linear-gradient(0deg, ${template?.data.backgroundCover[0]} 0%, ${template?.data.backgroundCover[1]} 100%)`,
            }}
        >
            <audio
                ref={audioRef}
                src={template?.data.song}
                onPlay={() => {
                    setIsPlaying(true);
                }}
                onPause={() => {
                    setIsPlaying(false);
                }}
                loop={true}
            ></audio>
            {hideCover && (
                <>
                    {!invitation?.onlyTemplate && (
                        <button
                            className="btn btn-barcode"
                            style={{
                                background: template?.data.primaryColor,
                                color: "white",
                            }}
                            onClick={() => setOpen(!open)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-qrcode"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                <path d="M7 17l0 .01" />
                                <path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                <path d="M7 7l0 .01" />
                                <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                <path d="M17 7l0 .01" />
                                <path d="M14 14l3 0" />
                                <path d="M20 14l0 .01" />
                                <path d="M14 14l0 3" />
                                <path d="M14 20l3 0" />
                                <path d="M17 17l3 0" />
                                <path d="M20 17l0 3" />
                            </svg>
                        </button>
                    )}
                    <button
                        className="btn btn-music"
                        style={{
                            background: template?.data.primaryColor,
                            color: "white",
                        }}
                        onClick={() => {
                            if (isPlaying) {
                                audioRef.current?.pause();
                            } else {
                                audioRef.current?.play();
                            }
                        }}
                    >
                        {isPlaying && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-music-x"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                <path d="M9 17v-13h10v9" />
                                <path d="M9 8h10" />
                                <path d="M22 22l-5 -5" />
                                <path d="M17 22l5 -5" />
                            </svg>
                        )}
                        {!isPlaying && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-music"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                <path d="M13 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                <path d="M9 17v-13h10v13" />
                                <path d="M9 8h10" />
                            </svg>
                        )}
                    </button>
                </>
            )}

            {template?.data.coverType == "cover2" ? (
                <div
                    style={{
                        position: "absolute",
                        height: "100%",
                    }}
                >
                    <img
                        className="bg-cover-2-img"
                        src={template?.data.backgroundCoverImage}
                        alt=""
                    />
                    <div
                        style={{
                            width: "100%",
                            background: `linear-gradient(0deg, ${template?.data.backgroundCover[1]} 20%, ${template?.data.backgroundCover[0]} 100%)`,
                            height: "100%",
                            top: 0,
                            position: "absolute",
                        }}
                    ></div>
                </div>
            ) : (
                <div>
                    <div className="ornament">
                        <img
                            src="https://is3.cloudhost.id/img-rfkystrk/first-template/cover-ornament.png"
                            alt=""
                            style={{
                                width: "100%",
                            }}
                        />
                    </div>
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-5.png"
                        alt=""
                        className="cover-leaves-5"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-6.png"
                        alt=""
                        className="cover-leaves-6"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-7.png"
                        alt=""
                        className="cover-leaves-7"
                    />

                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/bride-and-groom.png"
                        alt=""
                        className="bride-and-groom"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-1.png"
                        alt=""
                        className="cover-leaves-1"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-2.png"
                        alt=""
                        className="cover-leaves-2"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-3.png"
                        alt=""
                        className="cover-leaves-3"
                    />
                    <img
                        src="https://is3.cloudhost.id/img-rfkystrk/first-template/leaves-cover-4.png"
                        alt=""
                        className="cover-leaves-4"
                    />
                </div>
            )}

            <div
                className={`cover-blurred-overlay ${
                    hideCover ? "hide-blurred-cover" : ""
                }`}
            ></div>
            <div className="cover-content">
                <div
                    className="cover-name league-spartan-regular"
                    style={{ color: template?.data.colorOnPrimary }}
                >
                    {template?.data.groomShortName}
                </div>
                <div
                    className="cover-and league-spartan-regular"
                    style={{ color: template?.data.colorOnPrimary }}
                >
                    &
                </div>
                <div
                    className="cover-name league-spartan-regular"
                    style={{ color: template?.data.colorOnPrimary }}
                >
                    {template?.data.brideShortName}
                </div>
                <div
                    className="invites-you kite-one-regular"
                    style={{ color: template?.data.colorOnPrimary }}
                >
                    We invite you to our wedding
                </div>
                <button
                    className={`btn kite-one-regular btn-cover ${
                        hideCover ? "btn-cover-hide" : ""
                    }`}
                    onClick={() => {
                        setHideCover(true);
                        document.body.style.overflow = "scroll";
                        audioRef.current?.play();
                    }}
                    style={{
                        background: template?.data.primaryColor,
                        color: template?.data.colorOnPrimary,
                    }}
                >
                    Open{" "}
                    <span className="cover-btn-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="12"
                            fill="none"
                            viewBox="0 0 13 12"
                        >
                            <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m1 11 4-5-4-5m7 10 4-5-4-5"
                            />
                        </svg>
                    </span>
                </button>
            </div>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                center
                showCloseIcon={false}
            >
                <h3
                    style={{ marginTop: 2, color: template?.data.primaryColor }}
                >
                    Show this QR at wedding gate.
                </h3>
                <div style={{ textAlign: "center" }}>
                    {!invitation?.onlyTemplate && (
                        <QRCode
                            value={invitation.serial}
                            level="H"
                            id="QRCode"
                        />
                    )}
                    <br />
                    <btn
                        className="btn-download-qr"
                        style={{ color: template?.data.primaryColor }}
                        onClick={() => {
                            const svg = document.getElementById("QRCode");
                            const svgData =
                                new XMLSerializer().serializeToString(svg);
                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            const img = new Image();
                            img.onload = () => {
                                canvas.width = img.width + 40;
                                canvas.height = img.height + 40;
                                ctx.strokeRect(
                                    0,
                                    0,
                                    canvas.width,
                                    canvas.height
                                );
                                ctx.fillStyle = "#FFFFFF";
                                ctx.fillRect(0, 0, canvas.width, canvas.height);
                                ctx.drawImage(img, 20, 20);
                                const pngFile = canvas.toDataURL("image/png");
                                const downloadLink =
                                    document.createElement("a");
                                downloadLink.download = "QRCode";
                                downloadLink.href = `${pngFile}`;
                                downloadLink.click();
                            };
                            img.src = `data:image/svg+xml;base64,${btoa(
                                svgData
                            )}`;
                        }}
                    >
                        Download QR
                    </btn>
                </div>
            </Modal>
        </div>
    );
};

export default Cover;
