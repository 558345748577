import { createSlice } from "@reduxjs/toolkit";

export const templateSlice = createSlice({
    name: "template",
    initialState: null,
    reducers: {
        setTemplate: (state, data) => {
            return (state = data.payload);
        },
    },
});

export const { setTemplate } = templateSlice.actions;

export default templateSlice.reducer;
