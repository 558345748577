import { createSlice } from "@reduxjs/toolkit";

export const invitationSlice = createSlice({
    name: "invitation",
    initialState: null,
    reducers: {
        setInvitation: (state, data) => {
            return (state = data.payload);
        },
    },
});

export const { setInvitation } = invitationSlice.actions;

export default invitationSlice.reducer;
