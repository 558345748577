import React, { useEffect, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./gift.css";
import { useSelector } from "react-redux";

function Gift() {
    const template = useSelector((state) => state.template);

    const [collapseContent, setCollapseContent] = useState("");
    const [showCollapseContent, setShowCollapseContent] = useState(false);
    const [banks, setBanks] = useState([]);
    const [activeButton, setActiveButton] = useState(-1);

    useEffect(() => {
        setBankAccount();
    }, []);

    const setBankAccount = () => {
        const gifts = template?.data.gifts;
        const bankAccounts = [];
        gifts.map((item) => {
            const jsonBank = JSON.parse(item);
            bankAccounts.push({
                bankName: jsonBank.bankName,
                accountName: jsonBank.accountName,
                accountNumber: jsonBank.accountNumber,
            });
        });
        setBanks(bankAccounts);
    };

    const notify = () =>
        toast("Berhasil disalin", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
            closeButton: false,
            bodyClassName: "league-spartan-medium",
            style: {
                backgroundColor: template?.data.primaryColor,
                color: template?.data.colorOnPrimary,
            },
        });

    return (
        <div
            className="gift-container"
            style={{ color: template?.data.primaryColor }}
        >
            <div className="league-spartan-regular couple-bride-groom-label gift-title">
                Gift
            </div>
            <div className="kite-one-regular gift-words">
                Your presence on our special day is the greatest gift we could
                ask for. However, if you wish to extend your generosity, we have
                made arrangements to make it easier. Thank you for being part of
                our journey.
            </div>

            <div>
                <div className="gift-bank-btn-wrapper">
                    {banks.map((bank, i) => {
                        return (
                            <button
                                key={i}
                                className={`btn bank league-spartan-regular`}
                                onClick={() => {
                                    if (activeButton == i) {
                                        setActiveButton(-1);
                                    } else {
                                        setActiveButton(i);
                                    }
                                    const newCollapseContent = `${bank.accountNumber} - ${bank.accountName}`;
                                    if (showCollapseContent) {
                                        if (
                                            newCollapseContent !=
                                            collapseContent
                                        ) {
                                            setCollapseContent(
                                                newCollapseContent
                                            );
                                        } else {
                                            setShowCollapseContent(false);
                                        }
                                    } else {
                                        setShowCollapseContent(true);
                                        setCollapseContent(newCollapseContent);
                                    }
                                }}
                                style={{
                                    border: `1px solid ${template?.data.primaryColor}`,
                                    backgroundColor:
                                        activeButton == i
                                            ? template?.data.primaryColor
                                            : template?.data.colorOnPrimary,
                                    color:
                                        activeButton == i
                                            ? template?.data.colorOnPrimary
                                            : template?.data.primaryColor,
                                }}
                            >
                                {bank.bankName}
                            </button>
                        );
                    })}
                </div>

                <div
                    className={`league-spartan-medium gift-collapse-content ${
                        showCollapseContent ? "show" : ""
                    }`}
                    onClick={() => {
                        navigator.clipboard.writeText(collapseContent);
                        notify();
                    }}
                >
                    {collapseContent} <CopyOutlined />
                </div>
            </div>
        </div>
    );
}

export default Gift;
