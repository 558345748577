import React, { useEffect, useState } from "react";

import "./countdown.css";
import { useSelector } from "react-redux";

const Countdown = () => {
    const template = useSelector((state) => state.template);

    // countdown
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        if (template) {
            const timer = setInterval(() => {
                const weddingDate = new Date(template?.data.countdown);

                const now = new Date();
                const diff = weddingDate.getTime() - now.getTime();

                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor(
                    (diff % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);

                setCountdown({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            }, 1000);
        }
    }, [template]);

    return (
        <div className="countdown">
            <img
                className="countdown-ornament"
                src="https://is3.cloudhost.id/img-rfkystrk/first-template/ornament-countdown.png"
                alt=""
            />
            <div
                className="countdown-wrapper"
                style={{ color: template?.data.secondaryColor }}
            >
                <div className="countdown-wrapper-item">
                    <div className="eb-garamond-bold countdown-time">
                        {countdown.days}
                    </div>
                    <div className="kite-one-regular countdown-label">days</div>
                </div>
                <div className="countdown-wrapper-item">
                    <div className="eb-garamond-bold countdown-time">
                        {countdown.hours}
                    </div>
                    <div className="kite-one-regular countdown-label">
                        hours
                    </div>
                </div>
                <div className="countdown-wrapper-item">
                    <div className="eb-garamond-bold countdown-time">
                        {countdown.minutes}
                    </div>
                    <div className="kite-one-regular countdown-label">min</div>
                </div>
                <div className="countdown-wrapper-item">
                    <div className="eb-garamond-bold countdown-time">
                        {countdown.seconds}
                    </div>
                    <div className="kite-one-regular countdown-label">sec</div>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
